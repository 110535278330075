import React, { useEffect } from 'react'
import { setAnalyticsContext, track } from 'analytics'

import { useDynamicPageContent } from 'modules/dynamicPages'

import type { ComponentPageSelectCampaignPageConfig } from 'typings/strapi.graphql'

import HeroSection from 'compositions/dynamicPages/HeroSection/HeroSection'
import BodySection from 'compositions/dynamicPages/BodySection/BodySection'


const SelectCampaignPage = () => {
  const data = useDynamicPageContent()

  const pageConfig = data?.config?.[0] as ComponentPageSelectCampaignPageConfig

  useEffect(() => {
    if (!pageConfig?.trackParams) {
      return
    }

    const { page, pageType } = pageConfig.trackParams

    setAnalyticsContext({
      page,
      pageType,
    })

    // @ts-expect-error
    track(`${page} page`)
  }, [ pageConfig?.trackParams ])

  return (
    <>
      <HeroSection data={data.heroSection} />
      <BodySection data={data.bodySection} />
    </>
  )
}

export default SelectCampaignPage
