import { useCallback, useMemo, useRef } from 'react'
import { useDevice } from 'device'
import { devicePx, scrollToElement } from 'helpers'

import { useSearchParams, type SearchParams } from 'router'
import { useCatalogueProducts } from 'modules/products'

import type { ProductsFeedPage } from 'typings/graphql'

import useCatalogueFilters from './useCatalogueFilters'
import useCatalogueSorting from './useCatalogueSorting'


export type UseCatalogueContextValueProps = {
  catalogueId: number
  catalogueKey: string
  catalogueName: ProductsModule.CatalogueName
  isEcommerce?: boolean
  isExtras?: boolean
  filtersStrategy?: ProductsModule.FiltersStrategy
  defaultFilters?: ProductsModule.UseCatalogueProductsProps['variables']['input']['filter']
  defaultSort?: ProductsModule.UseCatalogueProductsProps['variables']['input']['sort']
  productsFeed: ProductsFeedPage
  limit?: number
}

const useCatalogueContextValue = (props: UseCatalogueContextValueProps) => {
  const {
    catalogueId,
    catalogueKey,
    catalogueName,
    defaultFilters,
    isEcommerce,
    isExtras,
    productsFeed,
    defaultSort,
    limit = 24,
    filtersStrategy = 'replace',
  } = props

  const { isMobile } = useDevice()

  const {
    appliedFilters,
    appliedFiltersForRequest,
    appliedFiltersCount,
    appliedBooleanFilters,
    appliedBooleanFiltersCount,
    appliedBooleanFiltersRequest,
    availableFilters,
    filterCatalogueId,
    isFetching: isFetchingFilters,
  } = useCatalogueFilters({
    catalogueId,
    catalogueKey,
    catalogueName,
  })

  const appliedSortingForRequest = useCatalogueSorting()

  const { isBot } = useDevice()

  const [ { page } ] = useSearchParams<SearchParams>()

  const pageRef = useRef(null)
  pageRef.current = Number.parseInt(page) || 1

  const variables = useMemo<ProductsModule.UseCatalogueProductsProps['variables']>(() => {
    const filter = filtersStrategy === 'merge'
      ? [ ...(defaultFilters || []), ...(appliedFiltersForRequest || []) ]
      : appliedFiltersForRequest || defaultFilters

    return {
      catalogueId: filterCatalogueId || catalogueId,
      catalogueKey,
      input: {
        limit,
        filter,
        offset: isBot ? (pageRef.current - 1) * limit : 0,
        sort: defaultSort || appliedSortingForRequest,
        productsFeed,
        // Standalone boolean filters
        ...appliedBooleanFiltersRequest,
      },
      withCount: true,
      availableForQueue: !isEcommerce && !isExtras,
      ecommTradingItem: isExtras ? 'Extras' : 'Ecommerce',
    }
  }, [
    appliedFiltersForRequest, appliedSortingForRequest, filtersStrategy, appliedBooleanFiltersRequest,
    catalogueId, catalogueKey, isEcommerce, isExtras, defaultFilters, defaultSort, productsFeed, filterCatalogueId,
    limit, isBot,
  ])

  const { data: catalogueProducts, cnstrcResultId: catalogueCnstrcResultId, isFetching: isFetchingProducts, fetchMore } = useCatalogueProducts({
    variables,
    skip: isFetchingFilters || appliedFilters?.BRANDS?.length > 8, // that's business logic, PF-749
    notifyOnNetworkStatusChange: true,
    ssr: false,
  })

  const productsLengthRef = useRef<number>()
  productsLengthRef.current = catalogueProducts?.products?.length

  const handleFetchMore = useCallback(() => {
    return fetchMore({
      variables: {
        ...variables,
        input: {
          ...variables.input,
          offset: productsLengthRef.current,
        },
      },
    })
  }, [ fetchMore, variables ])

  const scrollToFilters = useCallback(() => {
    if (isMobile) {
      setTimeout(() => {
        // scroll to the top of the list
        scrollToElement('#catalogueFilters', { onlyUp: true, compensation: devicePx(-20), smooth: true })
      }, 50)
    }
  }, [ isMobile ])

  return {
    catalogueId,
    catalogueKey,
    catalogueName: catalogueName,
    products: catalogueProducts?.products,
    count: catalogueProducts?.count,
    cnstrcResultId: catalogueCnstrcResultId,
    availableFilters,
    appliedFilters,
    appliedFiltersCount,
    appliedBooleanFilters,
    appliedBooleanFiltersCount,
    isFetchingFilters,
    isFetchingProducts: isFetchingProducts,
    isEcommerce,
    isExtras,
    fetchMore: handleFetchMore,
    scrollToFilters,
  }
}


export default useCatalogueContextValue
