import { useMemo } from 'react'
import { useSearchParams } from 'router'
import { useFt } from 'hooks'
import { constants } from 'helpers'

import useAvailableFilters from './useAvailableFilters'
import type { FilterData } from './useAvailableFilters'


type MemoResult = {
  appliedFilters: CataloguePages.AppliedFilters
  appliedFiltersForRequest: CataloguePages.AppliedFiltersForRequest
  appliedFiltersCount: number
  appliedBooleanFilters: CataloguePages.AppliedBooleanFilters
  appliedBooleanFiltersCount: number
  appliedBooleanFiltersRequest: CataloguePages.AppliedBooleanFiltersRequest
  filterCatalogueId: number
}

const filterActualByAvailable = (actual: string, available: FilterData['values'], typeSelection: FilterData['filterSelectionType']): string[] => {
  if (!actual || !available?.length) {
    return null
  }

  let result

  if (typeSelection === 'MULTIPLE') {
    result = actual.split(',').filter((item) => item && available.find(({ id }) => item === id))
  }
  else {
    result = available.find(({ id }) => actual === id) ? [ actual ] : null
  }

  return result?.length ? result : null
}

const BOOLEAN_FILTERS_SEARCH_PARAMS_KEY_MAP = {
  gwp: 'giftWithPurchase',
  sale: 'hasDiscount',
}

type UseCatalogueFiltersProps = {
  catalogueId: number
  catalogueKey: string
  catalogueName: ProductsModule.CatalogueName
}

const useCatalogueFilters = ({ catalogueId, catalogueKey, catalogueName }: UseCatalogueFiltersProps) => {
  const isEcommerceTwoZeroEnabled = useFt(constants.features.ecommerceTwoZero)

  const [ searchParams ] = useSearchParams()

  const { availableFilters, isFetching } = useAvailableFilters({
    catalogueId,
    catalogueKey,
    catalogueName,
  })

  const {
    appliedFilters,
    appliedFiltersForRequest,
    appliedFiltersCount,
    appliedBooleanFilters,
    appliedBooleanFiltersCount,
    appliedBooleanFiltersRequest,
    filterCatalogueId,
  } = useMemo<MemoResult>(() => {
    let result = {
      appliedFilters: null,
      appliedFiltersForRequest: null,
      appliedFiltersCount: 0,
      appliedBooleanFilters: null,
      appliedBooleanFiltersCount: 0,
      appliedBooleanFiltersRequest: null,
      filterCatalogueId: null,
    }

    // TODO: Move all standalone filters to same availability system as other filters — added on 11–12–2023 by algeas
    if (isEcommerceTwoZeroEnabled) {
      result.appliedBooleanFilters = Object.keys(BOOLEAN_FILTERS_SEARCH_PARAMS_KEY_MAP).reduce((acc, filterSearchParamKey) => {
        if (searchParams[filterSearchParamKey] === 'true') {
          const filterKey = BOOLEAN_FILTERS_SEARCH_PARAMS_KEY_MAP[filterSearchParamKey]

          acc.push(filterSearchParamKey)

          result.appliedBooleanFiltersCount += 1
          result.appliedBooleanFiltersRequest = result.appliedBooleanFiltersRequest || {}
          result.appliedBooleanFiltersRequest[filterKey] = true
        }

        return acc
      }, [])
    }

    if (!availableFilters) {
      return result
    }

    result.appliedFilters = Object.values(availableFilters)
      .reduce((acc, { id, filterType, filterSelectionType, values }) => {
        const searchParamsValue = searchParams[filterType.toLowerCase()] || searchParams[id]
        const filterValue = filterActualByAvailable(searchParamsValue, values, filterSelectionType)

        if (filterValue?.length) {
          result.appliedFiltersCount += filterValue?.length

          acc[filterType] = filterValue

          if (filterType === 'CATALOGUE') {
            // TODO: Constructor catalogue integration - added on 2024-11-18 by girilloid
            result.filterCatalogueId = parseInt(filterValue[0])

            return acc
          }

          result.appliedFiltersForRequest = result.appliedFiltersForRequest || []
          result.appliedFiltersForRequest.push({ type: filterType, filterValueIdList: filterValue })
        }

        return acc
      }, {})

    // ATTN we drop category if there are brands or notes
    //   because brands & notes is a "search by your knowledge"
    //   when category - is an our recommendations as a service PF-749
    if (
      (result.appliedFilters.BRANDS || result.appliedFilters.NOTES || result.appliedFilters.TAGS)
      && result.appliedFilters.CATEGORY
    ) {
      result.appliedFiltersCount--

      result.appliedFiltersForRequest.filter(({ type }) => type !== 'CATEGORY')

      delete result.appliedFilters.CATEGORY
    }

    return result
  }, [ availableFilters, isEcommerceTwoZeroEnabled, searchParams ])

  return {
    appliedFilters,
    appliedFiltersForRequest,
    appliedFiltersCount,
    appliedBooleanFilters,
    appliedBooleanFiltersCount,
    appliedBooleanFiltersRequest,
    availableFilters,
    filterCatalogueId,
    isFetching,
  }
}


export default useCatalogueFilters
